import React, { useContext } from "react";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import MenuContext from "../../context/MenuContext";
import Anchor from "../elements/Anchor";

const Stores = () => {
  const { stores } = useContext(MenuContext);

  if (stores.length === 0) {
    return null;
  }
  return (
    <div className="store-container d-flex flex-row flex-lg-column">
      {stores.map((store) => {
        return (
          <div
            key={store.store_title}
            className="store-card p-3 m-2 bg-white d-flex flex-column flex-lg-row justify-content-between"
          >
            <div>
              <StaticImage
                src="../../images/icons/location-mobile.png"
                alt="location icon"
                className="location-icon mb-2"
              />
              <h2 className="text-primary">{store.store_title}</h2>
              <h4>{store.store_address}</h4>
              <Anchor
                href={`tel:+30${store.telephone}`}
                className="d-block mb-2"
              >
                <span className="text-primary">Τηλ:</span> {store.telephone}
              </Anchor>
              <Anchor
                href={`tel:+30${store.telephone}`}
                className="d-block mb-2"
              >
                <span className="text-primary">Κιν:</span> {store.mobile}
              </Anchor>
              <p className="mb-2">
                <span className="text-primary">Fax:</span> {store.fax}</p>
              <Anchor href={`mailto:${store.email}`} className="mb-2">
                <span className="text-primary">Email:</span> {store.email}
              </Anchor>
            </div>
            <GatsbyImage
              image={store.store_image.gatsbyImageData}
              alt={store.store_image.alt || "store image"}
              className="store-image mt-4 mt-lg-0"
            />
          </div>
        );
      })}
    </div>
  );
};

export default Stores;
